import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./adamasTextBlock.module.scss"

type AdamasTextBlockProps = {
  orderNum?: string
}

export function AdamasTextBlock({ orderNum }: AdamasTextBlockProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container>
        <p className={styles.mainText}>
          20 случайным клиентам Халва и ADAMAS возмещают стоимость покупок до 100% в виде кэшбэка на
          карту ХАЛВА.
        </p>
        <p className={styles.text}>
          Совершай покупки картой Халва в период с 14.06.2021 г. по 11.07.2021 г. и испытай удачу.
          Быть может, именно тебе мы возместим покупку!
        </p>
      </Container>
    </section>
  )
}
