import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import ImgBg from "./ImgBg"

import text from "./img/text.svg"

import * as styles from "./adamas.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Заказать карту"

type BannerAdamasProps = {
  orderNum?: string
}

export function BannerAdamas({ orderNum }: BannerAdamasProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <ImgBg alt="bg image" className={styles.img} />
      <Container className={styles.container}>
        <img alt="100% кэшбэк" src={text} className={styles.h1} />
        <div className={styles.text}>Каждую неделю 5-ти клиентам</div>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
