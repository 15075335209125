import React from "react"

import Header from "../../Headers/Main"
import { LogoAdamas } from "./LogoAdamas"

import * as styles from "./headerAdamas.module.scss"

export function HeaderAdamas({ phones }: { phones?: string[] }) {
  return (
    <Header phones={phones}>
      <div className={styles.logoOuter}>
        <LogoAdamas />
      </div>
    </Header>
  )
}
