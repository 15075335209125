import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/base"
import { HeaderAdamas } from "../components/Adamas/Header"
import { BannerAdamas } from "../components/Adamas/Banner"
import Advantages from "../components/Advantages"
import { AdamasTextBlock } from "../components/Adamas/TextBlock"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import Footer from "../components/Footer"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { FormPKW } from "../components/FormPKW"

export default function AdamasPage({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout noIndex={noIndex}>
      <HeaderAdamas phones={phones} />
      <BannerAdamas orderNum="1" />
      <Advantages orderNum="2" />
      <AdamasTextBlock orderNum="3" />
      <Conditions rate={halvaOstatok.value} orderNum="4" />
      <WaysToGetCard orderNum="5" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="6"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Footer shortText={ligal} phones={phones} />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/adamas/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
